.multiple-form-button {
  @apply flex-1 font-medium text-[1rem] md:text-[2rem] bg-[#020331] text-white shadow-xl p-8 rounded-[2rem] hover:text-primary bg-opacity-50 w-fit cursor-pointer;
  text-shadow: 4px 4px #000;
}

.result-section .TableCell {
  @apply bg-transparent text-white;
}

.g2-html-annotation .Text_view_primary {
  color: white !important;
}
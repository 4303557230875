header .Tabs-Tab .ListItem {
  @apply text-[1rem] md:!text-[2rem] lg:!text-[3rem] !bg-bg-ternary !text-white !shadow-xl !p-8 rounded-[2rem] hover:!text-primary bg-opacity-50;
  @apply font-medium;
  text-shadow: 4px 4px #000;
}

header.bg-bg-primary2 .Tabs-Tab .ListItem {
  @apply !bg-bg-ternary2 hover:!text-primary2;
}

header.bg-bg-primary3 .Tabs-Tab .ListItem {
  @apply !bg-[#222225] hover:!text-primary3;
}

header .TabsLine {
  display: none;
}

header .TabsFitModeScrollWrapper {
  @apply justify-end;
}

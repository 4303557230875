.probability-section-button {
  @apply flex-1 font-medium text-[1rem] md:text-[2rem] bg-bg-ternary text-white shadow-xl p-8 rounded-[2rem] hover:text-primary bg-opacity-50;
  text-shadow: 4px 4px #000;
}

.probability-section-button_active {
  @apply text-primary;
}

.probability-section .Tabs-List {
  @apply flex justify-between w-full;
}

.probability-section .Tabs-Tab {
  @apply flex-1 w-full;
}

.probability-section .TabsLine {
  display: none;
}

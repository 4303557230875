@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

html,
body {
  font-family: "Montserrat", "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  min-height: 100%;
  @apply bg-bg-secondary;
}

#root {
  width: 100%;
  min-height: 100%;
}

.Select .Text,
.TextField .Text {
  @apply text-white text-[1.5rem];

  --size-text-l: 1.5rem;
  --size-text-xs: 1.2rem;
}

.Select-Placeholder {
  @apply text-gray-500;
}

.Select-ControlValue {
  @apply text-black;
}

.FieldLabel-Star {
  @apply text-red-600;
}

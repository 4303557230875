.form-section-button {
  @apply w-fit font-medium text-[1rem] py-6 px-16 md:text-[2rem] bg-bg-ternary text-white shadow-xl rounded-[2rem] hover:text-primary bg-opacity-50;
  text-shadow: 4px 4px #000;
}

.form-section-button:disabled {
  @apply bg-gray-400 text-gray-700 cursor-not-allowed hover:text-gray-700;
  text-shadow: none;
}

.form-section .Collapse .Collapse-Label * {
  @apply text-white text-[4rem];
  --graphics-size-s: 3rem;
}

.form-section .Collapse * .Collapse-Label * {
  @apply !text-[2rem];
  --graphics-size-s: 1.5rem;
}

.form-section * {
  @apply text-white;
  --color-control-bg-primary: #1a8b60;
  --color-control-bg-primary-hover: #1a8b60;
  --color-control-bg-border-focus: #1a8b60;
}

.form-section .TextField-CounterButton * {
  color: black;
}

.form-section .Radio-Input,
.form-section .Checkbox-Input:not(:checked) {
  @apply bg-white;
}

.form-section
  .CheckboxGroup_direction_column
  .CheckboxGroup-Item:not(:last-child) {
  margin-bottom: 1rem;
}

.Checkbox:hover {
  text-decoration: underline;
}
.form-section .Button_disabled {
  @apply !bg-gray-400 text-gray-700 cursor-not-allowed hover:text-gray-700;
  text-shadow: none;
}

.form-section .Radio-Label {
  @apply shrink-0;
}

.form-section :not(.small-label) .FieldLabel {
  @apply shrink-0 min-w-[480px] max-lg:min-w-fit;
}

.form-section .small-label .FieldLabel {
  @apply min-w-fit;
}

.result-section .TableCell {
  @apply bg-transparent text-white;
}

@media (max-width: 768px) {
  .form-section .Collapse-Label * {
    @apply text-white text-[2rem];
    --graphics-size-s: 2rem;
  }
}
